import PropTypes from "prop-types";
import React from "react";

const AppointmentForm = ({ email, emails }) => {
  let [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  let emailContent = 
    <input
      className='hidden'
      id='Werknemer'
      name='Werknemer'
      readOnly
      value={email}
    />;
  

  if (!email && emails.length) {
    const selectContent = emails.map(item => 
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    );

    emailContent = 
      <div className='w-full mb-4 lg:mb-8'>
        <label className='block mb-2' htmlFor='Werknemer'>
          Met wie wil je een afspraak?
        </label>
        <select
          className='select'
          id='Werknemer'
          name='Werknemer'
          placeholder='Teamlid'
          onChange={handleChange}
          required
        >
          {selectContent}
        </select>
      </div>;
    
  }

  return (
    <form
      action='/bedankt'
      className='flex flex-wrap'
      data-netlify='true'
      method='post'
      name='integraal-appointment'
      /* eslint-disable-next-line */
      netlify-honeypot='bot-field'
    >
      <input type='hidden' name='bot-field' />
      <input type='hidden' name='form-name' value='integraal-appointment' />

      <input
        type='hidden'
        name='Onderwerp'
        value={`Nieuwe afspraak van ${state.email} via 'integraal.be'`}
      />

      <div className='w-full lg:w-1/2 lg:pr-4 mb-4 lg:mb-8'>
        <label className='block mb-2' htmlFor='firstName'>
          Voornaam *
        </label>
        <input
          className='input'
          id='firstName'
          name='firstName'
          onChange={handleChange}
          placeholder='Uw voornaam'
          required
        />
      </div>
      <div className='w-full lg:w-1/2 lg:pl-4 mb-4 lg:mb-8'>
        <label className='block mb-2' htmlFor='lastName'>
          Achternaam *
        </label>
        <input
          className='input'
          id='lastName'
          name='lastName'
          onChange={handleChange}
          placeholder='Uw achternaam'
          required
        />
      </div>
      <div className='w-full lg:w-1/2 lg:pr-4 mb-4 lg:mb-8'>
        <label className='block mb-2' htmlFor='phone'>
          Telefoonnummer *
        </label>
        <input
          className='input'
          id='phone'
          name='phone'
          onChange={handleChange}
          placeholder='Uw telefoon- of gsmnummer'
          required
          type='phone'
        />
      </div>
      <div className='w-full lg:w-1/2 lg:pl-4 mb-4 lg:mb-8'>
        <label className='block mb-2' htmlFor='email'>
          E-mail *
        </label>
        <input
          className='input'
          id='email'
          name='email'
          onChange={handleChange}
          placeholder='Uw e-mailadres'
          required
          type='email'
        />
      </div>
      {emailContent}
      <div className='w-full mb-4 lg:mb-6'>
        <label className='block mb-2' htmlFor='extraInfo'>
          Extra Informatie
        </label>
        <textarea
          className='input h-56'
          id='extraInfo'
          name='extraInfo'
          onChange={handleChange}
          placeholder='Extra informatie'
        />
      </div>
      <button
        className='bg-secondary hover:bg-tertiary font-bold px-6 py-4 shadow-md text-sm text-white transition-bg uppercase'
        type='submit'
      >
        Verzenden
      </button>
    </form>
  );
};

AppointmentForm.propTypes = {
  email: PropTypes.string,
  emails: PropTypes.array
};

export default AppointmentForm;
