import { Link, graphql } from "gatsby";
import AppointmentForm from "../components/appointment-form";
import Page from "../components/page";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";

const ContactPage = ({ data }) => {
  const emailData = data.employees.edges.map(employee => {
    return {
      label: `${employee.node.firstName} ${employee.node.lastName}`,
      value: employee.node.email
    };
  });

  return (
    <Page>
      <SEO title='Contact' />
      <div className='container px-8 mb-12 lg:mb-24 mt-4 lg:mt-16'>
        <h2 className='title mb-6'>Contact</h2>
        <div className='wysiwyg'>
          <p>
            U vindt alle informatie en contactgegevens per teamlid bij{" "}
            <Link className='' to='/team'>
              Team
            </Link>
          </p>
          <p>
            Als u twijfelt bij wie u best terecht kan , kan u contact opnemen
            met Katlijn Willems, praktijkcoördinator{" "}
            <a href={`mailto:${data.site.siteMetadata.email}`}>
              {data.site.siteMetadata.email}
            </a>{" "}
            {/* <a href={`tel:${data.site.siteMetadata.phone}`}>
              {data.site.siteMetadata.phone}
            </a> */}
          </p>
          <p>
            Omdat we volle aandacht willen geven tijdens de begeleiding, staat
            de telefoon af tijdens de gesprekken. Er is dus veel kans dat u op
            een antwoordapparaat terecht komt. Spreek dan zeker uw naam en
            telefoonnummer in, we bellen u dan terug.
          </p>
        </div>
        <a
          className='bg-secondary hover:bg-tertiary font-bold focus:outline-none inline-block px-4 py-3 mb-10 mt-2 shadow-md text-sm text-white transition-bg uppercase  px-4 py-3 text-white'
          href='#afspraak'
        >
          Maak een afspraak
        </a>
        <h2 className='title mb-6'>Bereikbaarheid</h2>
        <div className='mb-12'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.432008071559!2d4.40408211575774!3d51.17426007958175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f129819c484f%3A0x44390aec932cbdc0!2sDennenlaan%2094%2C%202610%20Antwerpen!5e0!3m2!1snl!2sbe!4v1571526744268!5m2!1snl!2sbe'
            width='100%'
            height='470'
            frameBorder='0'
            allowFullScreen=''
            style={{ backgroundColor: "#f3f3f3" }}
          />
        </div>
        <div className='wysiwyg'>
          <p>
            <b>Adres</b>
            <br />
            Dennenlaan 94 te Wilrijk (naast huisartsengroep Groenenborg)
          </p>
          <p>
            <b>Per auto</b>
            <br />
            Makkelijk bereikbaar vanuit Antwerpen Centrum, Brussel of Mechelen
            vlakbij afrit Wilrijk E19 of A12
          </p>
          <p>
            <b>Centraal Station of Berchem Station</b>
            <br />
            Vanaf daar bus 21
          </p>
          <p>
            <b>Bus: 21</b>
            <br />
            Halte: Wilrijk Dennenlaan
            <br />
            Op 50m van de praktijk
          </p>
          <p>
            <b>Bus: 33</b>
            <br />
            Halte: Wilrijk Dennenlaan
            <br />
            Op 50m van de praktijk
          </p>
          <p>
            <b>Stadsfiets:</b>
            <br />
            Velo 273 campus Groenenborger <br />
            Velo 275 Sneeuwbes <br />
            Beiden op 500 m van de praktijk
          </p>
        </div>
        <h2 id='afspraak' className='title mb-6'>
          Maak een afspraak
        </h2>
        <AppointmentForm emails={emailData} />
      </div>
    </Page>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    employees: allContentfulEmployee(
      sort: { fields: [createdAt], order: ASC }
    ) {
      edges {
        node {
          firstName
          lastName
          email
        }
      }
    }
    site {
      siteMetadata {
        address
        email
        phone
      }
    }
  }
`;
